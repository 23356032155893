/** File generated programmatically bin amf task:generate-typescript-definitions */

export const AZIENDE_APPROVAL = ['in approvazione cliente', 'in approvazione rivenditore', 'approvato'] as const;
export const AZIENDE_AXERVERECURRINGMETHOD = ['axerve-paypal', 'axerve-cc'] as const;
export const AZIENDE_STATUS = ['prospect', 'attivo', 'testing', 'tested', 'ex'] as const;
export const CONTENTDISPOSITION = ['inline', 'attachment'] as const;
export const CRM_ANNIATTIVITA = ['da 0 a un 1 anno', 'tra 1 e 3 anni', 'tra 3 e 10 anni', 'tra 10 e 20 anni', 'più di 20 anni'] as const;
export const CRM_ASSOCIAZIONI = ['BNI', 'Assintel', 'Confindustria', 'Netcomm', 'AssoTLD'] as const;
export const CRM_CATEGORIAPROSPECT = ['digital agency', 'web agency', 'ecommerce agency', 'web design', 'web freelance', 'azienda di supporto IT', 'agenzia comunicazione', 'system integrator', 'software as a service', 'sviluppo applicativi web', 'digital trasformation', 'agenzia marketing', 'software house - sviluppo', 'software erp', 'business intelligence', 'azienda crypto-valute', 'azienda metaverso', 'AI - deep learning e ricerca', 'data analytics', 'aziende di formazione', 'azienda consulenza e sicurezza', 'internet service provider - fornitore di accesso', 'wisp'] as const;
export const CRM_CLIENTI = ['da 0 a 10', 'da 10 a 100', 'da 100 a 1000', 'da 1000 a 10000', 'più di 10000'] as const;
export const CRM_DIMENSIONECLIENTI = ['privato', 'piccolo', 'piccolo media', 'grande', 'professionisti', 'pubblica amministrazione', 'aziende no-profit'] as const;
export const CRM_DIPENDENTI = ['freelance', 'da 1 a 5', 'da 6 a 20', 'da 21 a 50', 'da 51 a 200', 'più di 200'] as const;
export const CRM_FASECRM = ['importata', 'limbo', 'telefonata', 'lista', 'fatto invito primo appuntamento', 'fatto sollecito invito primo appuntamento', 'fatto secondo sollecito invito primo appuntamento', 'primo appuntamento', 'fare invito secondo appuntamento', 'fatto invito al secondo appuntamento', 'fatto sollecito invito al secondo appuntamento', 'secondo appuntamento', 'fare offerta', 'fare followup offerta', 'onboarding', 'onboarding test', 'onboarding attivo', 'migrazione', 'fare followup onbording', 'sviluppare', 'coccolare', 'nessun riscontro', 'nessun riscontro avvisato', 'recuperare', 'gestione con Seeweb'] as const;
export const CRM_FATTURATO = ['da 0 a 100 K', 'da 100 K a 500 K', 'da 500K a 1 M', 'da 1M a 10 M', 'più di 10 M'] as const;
export const CRM_INTERESSEPARTNERSHIP = ['tanto', 'abbastanza', 'poco', 'no'] as const;
export const CRM_PREFERENZAFATTURAZIONE = ['diretta al cliente', 'white label', 'mista'] as const;
export const CRM_PROVIDERSBIG = ['Amazon', 'Google', 'Microsoft', 'Alibaba'] as const;
export const CRM_PROVIDERSCH = ['tinext.com', 'hostpoint.ch', 'infomaniak.com', 'hoststar.ch', '4host.ch', 'tritema.ch', 'abrweb.ch', 'webkeeper.ch', 'scibile.ch', 'hostingorilla.com', 'ticyweb.ch', 'easyname.ch', 'domain.ch', 'exion.ch', 'softcodex.ch', 'webland.ch', 'exoscale.com', 'cloudscale.ch', 'tineo.ch', 'metanet.ch', 'xelon.ch', 'neolo.com', 'moresi.ch', 'assolo.net', 'exigo.ch'] as const;
export const CRM_PROVIDERSIT = ['seeweb.it', 'aruba.it', 'shellrent.com', 'kinsta.com', 'serverplan.com', 'netsons.com', 'keliweb.it', 'webhosting.it', 'vhosting-it.com', 'host.it', 'interserver.net', 'supporthost.it', 'ergonet.it', 'hostinger.it', 'siteground.com', 'hostgator.com', 'register.it', 'coretech.it', 'irideos.it', 'magentiamo.it', 'pianetahosting.com', 'maxvalle.it', 'layertre.it', 'hostingsolutions.it', 'hostingperte.it', 'itroom.it', 'grintahosting.it', 'tophost.it'] as const;
export const CRM_PROVIDERSMONDO = ['webtropia.com', 'ovhcloud.com', 'flamenetworks.com', 'gandi.net', 'noamweb.com', 'presshost.com.au', 'factoriadigital.com', 'cloudsigma.com', 'atlantic.net', 'hetzner.com', 'scaleway.com', 'fastcomet.com', 'digitalocean.com', 'cloudways.com', 'nexcess.net', 'mgt-commerce.com', 'performive.com', 'mgtclusters.com', 'greengeeks.com', 'godaddy.com', 'linode.com', 'ionos.de', 'contabo.com', 'backblaze.com', 'stablepoint.com', 'brixly.uk', 'konsolex.onthecloud.srl'] as const;
export const CRM_QUALITALEAD = ['alta', 'media', 'bassa', 'spam'] as const;
export const DEPARTMENT = ['primo livello', 'secondo livello', 'amministrazione', 'commerciale'] as const;
export const LANGUAGE = ['it', 'en', 'fr', 'de'] as const;
export const LOG_TIPO = ['auth', 'edit', 'task', 'other'] as const;
export const OPERATING_SYSTEMS = ['AlmaLinux', 'AlmaLinux 9', 'AlmaLinux 8', 'Debian', 'Debian 12', 'Debian 11', 'Linux', 'Ubuntu', 'Ubuntu 24.04 LTS', 'Ubuntu 22.04 LTS', 'Ubuntu 20.04 LTS', 'OPNSense'] as const;
export const OPERATORI_AUTH = ['attivita', 'aziende', 'servizi', 'documenti', 'sistemi', 'ecommerce', 'contabilita'] as const;
export const OPERATORI_NOTIFICATIONS = ['general_checks', 'draft_invoices', 'new_tickets', 'out_of_office', 'cancellations', 'billing', 'free_period', 'new_partner', 'offers', 'daily_checks', 'second_level'] as const;
export const OPPORTUNITA_STATO = ['attesa', 'accettata', 'chiusa'] as const;
export const ORDINI_MOTIVAZIONERIFIUTO = ['prezzo', 'altro fornitore', 'tempistiche', 'non più necessario', 'spam', 'nessun riscontro', 'altro'] as const;
export const ORDINI_STATOOFFERTA = ['bozza', 'inviata', 'sollecitata', 'rifiutata', 'secondo_sollecito', 'sollecito_rivenditore', 'confermata_attesa', 'stand_by'] as const;
export const ORDINI_STATOORDINE = ['avviato', 'annullato', 'concluso'] as const;
export const ORDINI_TESTINGMODE = ['rinnova', 'disdici'] as const;
export const ORDINI_TIPO = ['ordine', 'offerta', 'carrello'] as const;
export const PAYMENTMETHOD = ['paypal', 'cc', 'sepa', 'axerve-paypal', 'axerve-cc'] as const;
export const PRODOTTIANAGRAFICA_IPDEDICATO = ['incluso', 'su richiesta', 'sì', 'no'] as const;
export const PRODOTTIANAGRAFICA_MONITORING = ['Solo ping', 'Completo', 'Internet Service Provider'] as const;
export const PRODOTTIANAGRAFICA_SEGMENTO = ['Cloud computing', 'Cloud hosting', 'Datacenter & Network Infrastructure Revenues', 'Managed services', 'Business connectivity', 'Other'] as const;
export const PRODOTTIANAGRAFICA_SUPPORT = ['standard', 'full managed', 'full service', 'proactive'] as const;
export const PRODOTTIORDINI_CUSTOMPRICE = ['reseller', 'single'] as const;
export const PRODOTTIORDINI_MOTIVAZIONEDISDETTA = ['Costi', 'Problemi tecnici', 'Assistenza tecnica', 'Gestione interna', 'Chiusura attività', 'Inutilizzo', 'Nessun riscontro', 'Altro', 'Upgrade', 'Test', 'Insoluto', 'Prezzo', 'Cambio agenzia'] as const;
export const PRODOTTIORDINI_PROVISIONBACKUPLOCATION = ['ch', 'eu'] as const;
export const PRODOTTIORDINI_PROVISIONLOCATION = ['ch-zu', 'ch-lu', 'it-mi', 'it-ro', 'gb', 'de', 'fr', 'pl', 'es', 'se', 'nl', 'us', 'ca', 'mx', 'br', 'jp', 'kr', 'sg', 'in', 'au', 'za'] as const;
export const PRODOTTIORDINI_STATOAMMINISTRATIVO = ['ordine', 'attivo', 'fatturare', 'attesa', 'non rinnovare', 'fatturato', 'disdetto'] as const;
export const PRODOTTIORDINI_STATOAMMINISTRATIVO_SELECTABLE = ['attivo', 'fatturare', 'attesa', 'non rinnovare'] as const;
export const PRODOTTIORDINI_STATOTECNICO = ['configurare', 'attivare', 'attivo', 'eliminare', 'sospeso', 'sospendere', 'riattivare'] as const;
export const PRODUCTCATEGORY = ['Domini', 'Domini — Add On', 'Cloud Shared Hosting', 'Cloud Mail', 'Cloud Dedicated Server — A risorse fisse', 'Cloud Dedicated Server — A risorse dinamiche', 'Cloud Dedicated Server — Stack', 'Cloud Dedicated Server — Premium Add On — Licenze', 'Cloud Dedicated Server — Premium Add On — Appliance', 'Storage', 'Storage — Agent', 'Certificati SSL', 'Domini — Altre Estensioni TLD'] as const;
export const REQUIREMENTTYPE = ['skill', 'tech skill', 'business sector'] as const;
export const RIGHEFATTURA_BOOKINGTYPE = ['new', 'renew', 'upgrade', 'refund', 'refund new'] as const;
export const SCADENZEPAGAMENTI_STATOSOLLECITO = ['Chiamata', 'Gestione con Rivenditore', 'Minaccia Sospensione', 'Sospeso', 'Riattivato', 'Causa', 'Eliminare/Nota credito'] as const;
export const SERVERS_SALTCLIENT = ['local', 'ssh'] as const;
export const SERVIZIDATIREGISTRAZIONIDOMINI_PROVIDER = ['srsplus', 'nic', 'infomaniak', 'eurid', 'eurodns', 'europeregistry', 'joker'] as const;
export const TIPIPROVVIGIONI = ['provvigioni', 'segnalazioni', 'comarketing', 'cashback'] as const;
export const WEB_SERVERS = ['NGINX', 'Apache'] as const;
