import type { ArrayElement } from "./typeutils";
import { PRODOTTIORDINI_PROVISIONLOCATION, PRODOTTIORDINI_PROVISIONBACKUPLOCATION } from '@/common/consts';

const BASE_FLAG_CODE = 0x1f185;

export function emojiflag(cc: string) {
  const codepoints: number[] = [];
  for (const c of cc.toLocaleLowerCase()) {
    codepoints.push(BASE_FLAG_CODE + c.charCodeAt(0));
  }
  return String.fromCodePoint(...codepoints);
}

export function any(q: string) {
  return function(el: Record<string, any>) {
    if (!q) {
      return true;
    }
    q = q.toLocaleLowerCase();
    const keys = Object.keys(el);
    for (const k of keys) {
      if (!el[k]) {
        continue;
      }
      const subject = String(el[k]).toLocaleLowerCase();
      if (subject.includes(q)) {
        return true;
      }
    }
    return false;
  };
}

// cache collator for performance
const sortCollator = new Intl.Collator(undefined, {
  usage: 'sort',
  sensitivity: 'base',
});

export function by<T>(f: (i: T) => any, reverse?: boolean): ((a: T, b: T) => number);
export function by<T extends Record<any, any>, F extends keyof T>(f: F, reverse?: boolean): ((a: T, b: T) => number);
export function by(f: string | ((i: any) => any), reverse = false) {
  if (typeof f === 'function') {
    return byFn(f, reverse);
  }
  return byProp<any, string>(f, reverse);
}

function byFn<T>(f: (i: T) => any, reverse = false) {
  const reverseFactor = reverse ? -1 : 1;
  return function(a: T, b: T) {
    if (!f) {
      return 0;
    }

    const valA = f(a);
    const valB = f(b);

    const aNull = typeof valA === 'undefined' || valA === null;
    const bNull = typeof valB === 'undefined' || valB === null;

    if (aNull && bNull) {
      return 0;
    }

    if (typeof valA == 'string' || typeof valB == 'string') {
      return sortCollator.compare(valA, valB) * reverseFactor;
    }

    if (aNull || valA < valB) {
      return -1 * reverseFactor;
    }

    if (bNull || valA > valB) {
      return reverseFactor;
    }

    return 0;
  };
}

function byProp<T extends object, F extends keyof T>(f: F, reverse = false) {
  return byFn((i: T) => i[f], reverse);
}

export function multiby(...ff: ((a: any, b: any) => number)[]) {
  return function(a: any, b: any) {
    for (const f of ff) {
      const result = f(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

// cache formatters for performance
const numFormatter = new Intl.NumberFormat(undefined);
const intFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
const dec2Formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });
const currFormatters: Record<string, Intl.NumberFormat> = {};
const conjunctionFormatter = new Intl.ListFormat();
const disjunctionFormatter = new Intl.ListFormat(undefined, { type: 'disjunction' });

export function formatNumber(value: number | string | null) {
  if (value === null) {
    return '';
  }

  value = Number(value);
  let formatted = numFormatter.format(value);
  if (isNaN(value)) {
    formatted = formatted.replace('NaN', '-');
  }

  return formatted;
}

export function formatCurrency(value: string | number | null, currency: string) {
  if (value === null) {
    return '';
  }

  let fmt = currFormatters[currency];
  if (!fmt) {
    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency,
    };
    fmt = new Intl.NumberFormat(undefined, options);
    currFormatters[currency] = fmt;
  }

  value = Number(value);
  let formatted = fmt.format(value);
  if (isNaN(value)) {
    formatted = formatted.replace('NaN', '-');
  }

  return formatted;
}

export function formatSize(size: string | number | null | undefined, base2 = false) {
  if (size === null || size === undefined) {
    return '';
  }

  size = Number(size);
  if (isNaN(size)) {
    return '';
  }

  const base = base2 ? 1024 : 1000;
  const suffix = base2 ? 'iB' : 'B';

  const units = ['', 'K', 'M', 'G', 'T', 'P'];
  let unitX = 0;
  while (size >= base && unitX < units.length) {
    unitX++;
    size /= base;
  }
  const fmt = unitX < 2 ? intFormatter : dec2Formatter;
  return `${fmt.format(size)} ${units[unitX]}${suffix}`;
}

export const formatDecimal2 = dec2Formatter.format;

export function formatValidOnly<T, R extends unknown[]>(f: (v: T, ...rest: R) => string): (v: T | null | undefined, ...rest: R) => string {
  return (v, ...rest) => {
    try {
      return v === null || v === undefined || (typeof v !== 'string' && isNaN(v as any)) ? '' : f(v, ...rest);
    } catch (e) {
      if (e instanceof RangeError) {
        return '';
      }
      throw e;
    }
  };
}

export function formatList(values: Iterable<string>, or = false) {
  const fmt = or ? disjunctionFormatter : conjunctionFormatter;
  return fmt.format(values);
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export async function encodeForSend(v: any) {
  if (v instanceof File || v instanceof Blob) {
    const buffer = new Uint8Array(await v.arrayBuffer());
    const content = btoa(buffer.reduce((p, c) => p + String.fromCharCode(c), ''));
    return {
      name: v instanceof File ? v.name : null,
      size: v.size,
      type: v.type,
      content,
    };
  } else {
    return v;
  }
}

export function zipObject<K extends readonly string[], V extends Record<K[number], any>>(keys: K, values: V): Record<K[number], V[K[number]]>;
export function zipObject<K extends readonly string[], V extends any[]>(keys: K, values: V): Record<K[number], V>;
export function zipObject(keys: string[], values: any[] | Record<string, any>): Record<string, any> {
  return keys.reduce((accumulator: Record<string, any>, key, index) => {
    accumulator[key] = Array.isArray(values) ? values[index] : values[key];
    return accumulator;
  }, {});
}

export function isPlainObject(obj: any): boolean {
  const prototype = Object.getPrototypeOf(obj);
  return  prototype === Object.getPrototypeOf({}) ||
    prototype === null;
}

export async function recursiveObjectPromiseAll(obj: any[] | Record<string, any>): Promise<any[] | Record<string, any>> {
  if (Array.isArray(obj)) {
    return await Promise.all(obj.map(v => typeof v === 'object' && !v.then && isPlainObject(v) ? recursiveObjectPromiseAll(v) : v));
  }
  const keys = Object.keys(obj);
  const result = await Promise.all(keys.map(key => {
    const value = obj[key];
    // Promise.resolve(value) !== value should work, but !value.then always works
    if (typeof value === 'object' && !value.then && isPlainObject(value) || Array.isArray(value)) {
      return recursiveObjectPromiseAll(value);
    }
    return value;
  }));
  return zipObject(keys, result);
}

export function getLayerOffset(evt: MouseEvent) {
  if (!(evt.target instanceof HTMLElement)) {
    return;
  }

  let el = evt.target;
  let x = 0;
  let y = 0;

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop) && el.offsetParent instanceof HTMLElement) {
    x += el.offsetLeft - el.scrollLeft;
    y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }

  x = evt.clientX - x;
  y = evt.clientY - y;

  return { x: x, y: y };
}

export function keyFor(value: unknown, fallback: string | number | symbol): string | number | symbol {
  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'symbol') {
    return value;
  }
  return fallback;
}

export function shuffle<V, T extends Array<V>>(a: T): T {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function pickRandom<T extends any[]>(a: T): ArrayElement<T> {
  const r = Math.floor(Math.random() * a.length);
  return a[r];
}

export function injectScript(src: string, target: HTMLElement = document.head): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', () => {
      reject(new Error(`Failed to load ${src}`));
    });
    target.appendChild(script);
  });
}

export function copyToClipBoard(firma: string) {
  navigator.clipboard.writeText(firma);
}

export function pop<T>(items: T | T[]): T | undefined {
  return Array.isArray(items) ? items.pop() : items;
}

export function saveAs(data: Blob, filename: string) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(data);
  link.dispatchEvent(new MouseEvent('click'));
}

// LOCATION
// I primi due caratteri dell'indice devono rappresentare l'ISO di uno stato per mostrarne la bandiera

export type ProvisionLocationCode = typeof PRODOTTIORDINI_PROVISIONLOCATION[number];
export type ProvionsBackupLocationCode = typeof PRODOTTIORDINI_PROVISIONBACKUPLOCATION[number];
export type LocationCode = ProvisionLocationCode | ProvionsBackupLocationCode;
export type Location = Record<LocationCode, string>;

export const mainLocation = {
  'ch-zu': 'Zurigo - Svizzera',
  'ch-lu': 'Lugano - Svizzera',
  'it-mi': 'Milano - Italia',
  'it-ro': 'Roma - Italia',
};

export const worldLocation = {
  gb: 'Regno Unito',
  de: 'Germania',
  fr: 'Francia',
  pl: 'Polonia',
  es: 'Spagna',
  se: 'Svezia',
  nl: 'Paesi Bassi',
  us: 'Stati Uniti',
  ca: 'Canada',
  mx: 'Messico',
  br: 'Brasile',
  jp: 'Giappone',
  kr: 'Corea del sud',
  sg: 'Singapore',
  in: 'India',
  au: 'Australia',
  za: 'Sud Africa',
};

export const locationBackup = {
  ch: 'Svizzera',
  eu: 'Unione Europea',
};

export const deployLocation = { ...mainLocation, ...worldLocation };
export const locations: Location = { ...deployLocation, ...locationBackup };
