<template>
  <div>
    <div>
      <div class="row product-head hidden-xs">
        <span class="col-sm-2 head-cell">{{ $t('Codice') }}</span>
        <span class="col-sm-4 head-cell">{{ $t('Nome') }} </span>
        <span class="col-sm-2 head-cell value-cell">{{ $t('Listino') }}</span>
        <span class="col-sm-2 head-cell value-cell">{{ $t('Sconto') }}</span>
        <span class="col-sm-2 head-cell value-cell">{{ $t('Importo') }}</span>
      </div>

      <ul v-for="(prodotti, dominio) in groupedProdotti" :key="dominio" class="product-list">
        <li v-for="prodotto in prodotti" :key="prodotto.id" class="row product-row" :class="{'striked': prodotto.disdire}">
          <span class="col-xs-4 col-sm-2 hidden-xs">{{ prodotto.codice }}</span>
          <span class="col-xs-8 col-sm-4 col-xs-12 descrizione">
            <span class="visible-xs-inline">{{ prodotto.codice }} - </span>
            <template v-if="prodotto.qt != 1">{{ prodotto.qt }} x </template>
            {{ prodotto.descrizione }}
          </span>
          <span class="col-xs-4 col-sm-2 value-cell">
            <span class="visible-xs">{{ $t('Listino') }}</span> {{ $currency(prodotto.importo, offerta.currency) }}
          </span>
          <span class="col-xs-4 col-sm-2 value-cell">
            <span class="visible-xs">{{ $t('Sconto') }}</span> {{ $currency(prodotto.sconto) }} %
          </span>
          <span class="col-xs-4 col-sm-2 value-cell">
            <span class="visible-xs">{{ $t('Importo') }}</span> {{ $currency(prodotto.imponibile, offerta.currency) }}
          </span>
        </li>
      </ul>
    </div>

    <div v-if="offerta.note_offerta" class="note_offerta">
      <h3>{{ $t('Note:') }}</h3>
      {{ offerta.note_offerta }}
    </div>

    <div style="max-width: 800px">
      <h3>{{ $t('Riepilogo:') }}</h3>

      <template v-if="isOffertaParco">
        <h4>{{ $t('Totale prodotti attuale:') }}</h4>
        <ul class="product-list">
          <offer-total :totali="offerta.totali_disdetti" durata="Annuale" :currency="offerta.currency" />
          <offer-total :totali="offerta.totali_disdetti" durata="Mensile" :currency="offerta.currency" />
          <offer-total :totali="offerta.totali_disdetti" durata="Una tantum" :currency="offerta.currency" />
        </ul>
        <h4>{{ $t('Totale nuova offerta:') }}</h4>
      </template>

      <ul class="product-list">
        <offer-total :totali="offerta.totali" durata="Annuale" :currency="offerta.currency" />
        <offer-total :totali="offerta.totali" durata="Mensile" :currency="offerta.currency" />
        <offer-total :totali="offerta.totali" durata="Una tantum" :currency="offerta.currency" />
      </ul>

      <ul class="product-list">
        <li v-if="Number(offerta.sconto_custom) > 0" class="row">
          <span class="col-xs-12 col-sm-4">{{ $t('Sconto in fattura') }}:</span>
          <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(-offerta.sconto_custom, offerta.currency) }}</span>
        </li>

        <li v-if="offerta.valore_storno > 0" class="row">
          <span class="col-xs-12 col-sm-4">{{ $t('Sconto per periodo non usufruito') }}:</span>
          <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(-offerta.valore_storno, offerta.currency) }}</span>
        </li>

        <li class="row">
          <span class="col-xs-12 col-sm-4">{{ $t('Totale (IVA Esclusa)') }}:</span>
          <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(offerta.gran_totale, offerta.currency) }}</span>
        </li>

        <li class="row">
          <span class="col-xs-12 col-sm-4"><strong>{{ $t('Totale Offerta (IVA Inclusa)') }}:</strong></span>
          <span class="col-xs-12 col-sm-2 value-cell"><strong>{{ $currency(offerta.gran_totale_ivato, offerta.currency) }}</strong></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.note_offerta, .descrizione {
  white-space: pre-line;
}

.striked,
.striked span {
  text-decoration: line-through;
}

.head-cell {
  font-weight: bold;
  text-align: left;
}

.value-cell {
  text-align: right;
}

.product-list {
  margin-top: 10px;
  margin-bottom: 25px;
}

.product-row {
  padding: 5px 0;

  &::after {
    display: block;
    border-bottom: 1px solid #f0f0f0;
    margin-left: calc($grid-gutter-width / 2);
    margin-right: calc($grid-gutter-width / 2);
  }
}
</style>

<script lang="ts">
import type { Offer, OfferProduct, OfferProductCancel, OfferProductMigratedFrom } from '@/resources';
import { defineComponent, type PropType } from 'vue';
import OfferTotal from './offer-total.vue';

export default defineComponent({
  name: 'OfferDetail',

  components: {
    OfferTotal,
  },

  props: {
    offerta: {
      type: Object as PropType<Offer>,
      required: true,
    },
  },

  computed: {
    groupedProdotti() {
      const prodotti: Record<string, ((OfferProduct | OfferProductCancel | OfferProductMigratedFrom) & {
        disdire: boolean;
      })[]> = {};

      for (const prodotto of this.offerta.prodotti_disdire) {
        const dom = prodotto.dominio ?? '';
        (prodotti[dom] = prodotti[dom] || []).push({ ...prodotto, disdire: true });
      }

      for (const prodotto of this.offerta.prodotti) {
        const dom = prodotto.dominio ?? '';
        if (prodotto.migratedFrom) {
          const oldDom = prodotto.migratedFrom.dominio ?? '';
          (prodotti[oldDom] = prodotti[oldDom] || []).push({ ...prodotto.migratedFrom, disdire: true });
        }
        (prodotti[dom] = prodotti[dom] || []).push({ ...prodotto, disdire: false });
      }

      return prodotti;
    },

    isOffertaParco() {
      for (const durata of Object.values(this.offerta.totali_disdetti)) {
        if (durata.scontato > 0) {
          return true;
        }
      }

      return false;
    },
  },
});
</script>
