<template>
  <pf-list-view :rows="items" :items-per-page="0" stacked key-name="codice" class="catalog">
    <template #default="{row}">
      <pf-list-item v-if="row.nome_listino">
        <template #heading>
          <span :title="row.nome_listino">{{ row.nome_listino }}</span>
        </template>

        <template #description>
          <p>{{ $t('Codice prodotto') }}: {{ row.codice }}</p>
          <p v-if="row.url">
            <a :href="row.url" target="_blank">
              {{ $t('Scheda') }} <arrow-up-right-from-square-icon />
            </a>
          </p>
          <p>
            <i18n-t v-if="!row.group_code && row.durata != 'Una tantum'" keypath="Prodotto disponibile solo con fatturazione {0}" tag="i">
              <template v-if="row.durata =='Annuale'">
                {{ $t('annuale') }}
              </template>
              <template v-else-if="row.durata =='Mensile'">
                {{ $t('mensile') }}
              </template>
            </i18n-t>
          </p>
        </template>

        <template #additional-info>
          <product-details :product="row" />
        </template>
      </pf-list-item>

      <pf-list-item v-else>
        <template #heading>
          <span><triangle-exclamation-icon /> {{ $t('Codice prodotto {0} non trovato', [row.codice]) }}</span>
        </template>
      </pf-list-item>
    </template>

    <template #action="{row}">
      <template v-if="row.nome_listino && !hidePrice">
        <div class="price">
          <s v-if="!alwaysFullPrice && (row.prezzo > row.prezzo_scontato)" class="full-price">
            <template v-if="showQt && row.qt">{{ row.qt }} x</template>
            {{ $currency(row.prezzo, currency) }}
          </s>
          <div class="final-price">
            <template v-if="showQt && row.qt">{{ row.qt }} x</template>
            {{ $currency(alwaysFullPrice ? row.prezzo : row.prezzo_scontato, currency) }}
          </div>
          <div v-if="!hidePlusVat">
            + {{ $t('IVA') }} {{ getPeriodo(row.durata) }}
          </div>
          <div v-else>
            {{ $t(row.durata) }}
          </div>
          <div v-if="login.is_reseller && row.prezzo_customer != undefined && row.prezzo_customer !== row.prezzo">
            {{ $t('Prezzo ai tuoi clienti') }}: {{ $currency(row.prezzo_customer, currency) }}
          </div>
        </div>
      </template>
      <template v-else-if="hidePrice">
        {{ $t('GRATIS') }}
      </template>
    </template>
  </pf-list-view>
</template>

<style lang="scss">
.catalog {
  .list-view-pf-stacked .list-group-item-heading {
    margin-bottom: calc($grid-gutter-width / 4);
  }

  .list-view-pf-view {
    margin-top: 0;
  }

  .list-view-pf-actions {
    display: flex;
    align-items: center;
  }

  .price {
    text-align: center;

    > strike {
      display: block;
    }
  }

  .final-price {
    font-size: 1.6em;
  }

  .list-group-item-text > :last-child {
    margin: 0;
  }

  svg {
    margin: 2px;
  }

  @media (max-width: $screen-xs-max) {
    .list-view-pf-additional-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .list-view-pf-actions {
      justify-content: space-between;
      flex-direction: column;
    }

    .order {
      margin-top: calc($grid-gutter-width / 4);
    }
  }

  @media (min-width: $screen-sm-min) {
    .price {
      padding-right: calc($grid-gutter-width / 4);
    }
  }

  @media (max-width: $screen-sm-max) {
    .list-group-item {
      padding: 0;
    }
  }

  @media (min-width: $screen-md-min) {
    .list-view-pf-description {
      min-width: 330px;
      width: 40%;
      max-width: 465px;
    }
  }
}
</style>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import { type NormalizedCatalogProduct } from '../pages/catalog.vue';
import ProductDetails from './product-details.vue';
import ArrowUpRightFromSquareIcon from '@vue-patternfly/icons/arrow-up-right-from-square-icon';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import { useLoginStore } from '@/store/login';

defineProps<{
  items: NormalizedCatalogProduct[];
  currency: string,
  alwaysFullPrice?: boolean;
  hidePlusVat?: boolean;
  hidePrice?: boolean;
  showQt?: boolean;
}>();

const login = useLoginStore();

function getPeriodo(durata: string) {
  let p = '';

  if (durata === 'Mensile') {
    p = `/ ${_t('MESE')}`;
  } else if (durata === 'Annuale') {
    p = `/ ${_t('ANNO')}`;
  } else {
    p = `/ ${_t('UNA TANTUM')}`;
  }

  return p;
}
</script>
