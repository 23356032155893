p<template>
  <ul class="radio-wrapper">
    <li v-for="(v, k) of opzioniLocation" :key="k">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="k"
          :checked="k === modelValue"
          @change="$emit('update:modelValue', k as ValueType)"
        >
        <template v-if="k !== 'altro'">&nbsp;{{ emojiflag(k.slice(0,2)) }}</template> {{ v }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { type Location, emojiflag } from '@common/utils';
import { useWizardQuestion } from '../composition';
import type { WizardInjection } from '../wizard.vue';

type ValueType = WizardInjection['sceltaLocationBackup'] | WizardInjection['sceltaLocationMondo'] | WizardInjection['sceltaLocationDeploy'];

defineProps<{
  modelValue: ValueType;
  name: string;
  required?: boolean;
  opzioniLocation: Partial<Location> & Partial<Record<'altro', string>>;
}>();


defineEmits<{
  (name: 'update:modelValue', v: ValueType): void;
}>();

useWizardQuestion();
</script>
