<template>
  <component :is="isForm ? 'form' : 'div'" role="form" class="form-billing" @submit.prevent="submit">
    <div v-if="loading" class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      <h3 class="blank-slate-pf-main-action">
        {{ $t('Caricamento dati di fatturazione...') }}
      </h3>
    </div>
    <fieldset v-else :disabled="embedFor !== 'prospect' && !company.id">
      <div v-if="embedFor === 'approval'" class="form-group">
        <div class="btn-group">
          <div>
            <label>{{ $t('Richiedi accesso come:') }} *</label>
          </div>
          <btn-radio v-model="company.approval" active-class="btn btn-success" inactive-class="btn btn-default" checked-value="in approvazione rivenditore" name="approval" required>
            {{ $t('Rivenditore') }}
          </btn-radio>
          <btn-radio v-model="company.approval" active-class="btn btn-success" inactive-class="btn btn-default" checked-value="in approvazione cliente" name="approval" required>
            {{ $t('Cliente Finale') }}
          </btn-radio>
        </div>

        <div v-if="company.approval" class="approval_info">
          <div v-if="company.approval === 'in approvazione rivenditore'">
            {{ $t("Dopo aver completato la compilazione delle informazioni richieste, procederemo ad attivare il tuo status di partner Artera.") }}
          </div>
          <div v-else-if="company.approval === 'in approvazione cliente'">
            {{ $t("Una volta che avrai condiviso le tue informazioni, effettueremo una selezione accurata del partner che meglio si adatta alle tue esigenze.") }}
          </div>
          {{ $t("Nessun vincolo contrattuale è applicato; il contratto è completamente privo di restrizioni, inclusi requisiti minimi d'acquisto. Avrai sempre la libertà di decidere come procedere.") }}
        </div>
      </div>

      <div v-if="!verificato || embedFor === 'prospect'" class="form-group">
        <div>
          <label>{{ $t('Tipologia:') }} *</label>
        </div>
        <div class="btn-group">
          <btn-radio v-model="company.tipologia" checked-value="Private" name="tipologia" :disabled="company.locked">
            {{ $t('Persona fisica') }}
          </btn-radio>
          <btn-radio v-model="company.tipologia" checked-value="Company" name="tipologia" :disabled="company.locked">
            {{ $t('Azienda') }}
          </btn-radio>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-12" :class="{'col-lg-6': !modal}">
          <label for="companyRagioneSociale">{{ ragioneSocialeLabel }} *</label>
          <input
            id="companyRagioneSociale"
            v-model="company.ragione_sociale"
            name="ragione_sociale"
            type="text"
            class="form-control"
            required
            maxlength="250"
            autocomplete="name"
            :readonly="company.locked"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-8" :class="{'col-lg-4': !modal}">
          <label for="companyNazione">{{ $t('Nazione') }} *</label>
          <select
            id="companyNazione"
            v-model="company.stato"
            name="stato"
            class="form-control"
            autocomplete="country"
            :disabled="company.locked"
            @change="capChange"
          >
            <option v-for="country in countries" :key="country.iso" :value="country.iso">
              {{ country.country }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-4" :class="{'col-lg-2': !modal}">
          <label for="companyCAP">{{ $t('CAP') }} *</label>
          <input
            id="companyCAP"
            v-model="company.cap"
            name="cap"
            type="text"
            class="form-control"
            required
            maxlength="10"
            autocomplete="postal-code"
            @change="capChange"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-5" :class="{'col-lg-4': !modal}">
          <label v-if="persona_fisica" for="companyIndirizzo">{{ $t('Indirizzo residenza') }} *</label>
          <label v-if="!persona_fisica" for="companyIndirizzo">{{ $t('Indirizzo sede') }} *</label>
          <input
            id="companyIndirizzo"
            v-model="company.indirizzo"
            name="indirizzo"
            type="text"
            class="form-control"
            required
            maxlength="250"
            autocomplete="street-address"
          >
        </div>

        <div class="form-group col-md-4" :class="{'col-lg-2': !modal}">
          <label for="companyCitta">{{ $t('Città') }} *</label>

          <input
            v-if="autoCities.length < 2"
            id="companyCitta"
            v-model="company.citta"
            name="citta"
            type="text"
            :readonly="autoCities.length == 1"
            class="form-control"
            required
            maxlength="250"
          >

          <select v-else id="companyCitta" v-model="company.citta" class="form-control" name="citta">
            <option v-for="citta of autoCities" :key="citta" :value="citta">
              {{ citta }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-3" :class="{'col-lg-2': !modal}">
          <label v-if="company.stato != 'CH'" for="companyProvincia">{{ $t('Provincia') }} *</label>
          <label v-if="company.stato == 'CH'" for="companyProvincia">{{ $t('Cantone') }} *</label>
          <input
            id="companyProvincia"
            v-model="company.provincia"
            name="provincia"
            type="text"
            :readonly="autoProvince"
            class="form-control"
            required
            maxlength="2"
          >
        </div>
      </div>

      <div v-if="company.stato == 'IT' || !persona_fisica" class="row">
        <div v-if="!persona_fisica" class="form-group col-md-6" :class="{'col-lg-4': !modal}">
          <label for="companyPiva">
            {{ $t('Partita IVA') }} <span v-if="company.stato == 'IT' && !company.codice_fiscale">*</span>
          </label>
          {{ ' ' }}
          <form-help-tooltip
            v-if="company.locked"
            :text="$t(
              `Non è possibile modificare la partita IVA. È necessario richiedere il cambio dei dati fatturazione compilando il relativo modulo che si trova nella sezione 'Documenti Utili'. Il modulo andrà poi inviato a finance{'@'}artera.net.`,
            )"
          />
          <input
            id="companyPiva"
            v-model="company.partita_iva"
            name="partita_iva"
            type="text"
            :required="company.stato == 'IT' && !company.codice_fiscale"
            class="form-control"
            maxlength="16"
            :readonly="company.locked"
          >
        </div>

        <div v-if="company.stato == 'IT'" class="form-group col-md-6" :class="{'col-lg-4': !modal}">
          <label for="companyCF">
            {{ $t('Codice fiscale') }} <span v-if="!company.partita_iva || persona_fisica">*</span>
          </label>
          <input
            id="companyCF"
            v-model="company.codice_fiscale"
            name="codice_fiscale"
            type="text"
            :required="!company.partita_iva || persona_fisica"
            class="form-control"
            maxlength="16"
            :readonly="company.locked"
          >
        </div>
      </div>

      <template v-if="embedFor === null || embedFor === 'prospect'">
        <h3>{{ $t('Modalità di fatturazione') }}</h3>
        <div class="row">
          <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
            <label for="currency">
              {{ $t('Valuta') }} *
              <form-help-tooltip
                v-if="company.locked || login.currency_locked"
                :text="$t(`Non è possibile modificare in modo autonomo la valuta. Scrivi a finance{'@'}artera.net per eventuali richieste in merito al cambio delle opzioni di fatturazione.`)"
              />
            </label>
            <select
              id="currency"
              v-model="company.currency"
              name="currency"
              class="form-control"
              required
              :disabled="embedFor !== 'prospect' && (company.locked || login.currency_locked)"
            >
              <option value="EUR">Euro</option>
              <option value="CHF">
                <!-- strano bug dove se value == innerHTML, l'attributo non viene assegnato al tag option. nbsp serve a differenziare i 2 valori senza mostrare niente di diverso all'utente -->
                CHF&nbsp;
              </option>
            </select>
          </div>

          <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
            <label>
              {{ $t('Fatturazione unica mensile') }}
              <form-help-tooltip
                :text="$t('Attivando questa opzione riceverai al massimo una fattura al mese, alla fine del mese, che comprenderà tutti i servizi rinnovati in quel mese.')"
              />
            </label>
            <div>
              <toggle
                v-model="company.fatturazione_mensile"
                name="fatturazione_mensile"
                :on-value="true"
                :off-value="false"
              />
            </div>
          </div>
        </div>

        <h3>{{ $t('Preferenze delle Comunicazioni') }}</h3>
        <div class="row">
          <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
            <label for="lingua">{{ $t('Lingua') }}</label>
            <select id="lingua" v-model="company.lingua" class="form-control" autocomplete="language" name="lingua">
              <option value="it">
                {{ $t('Italiano') }}
              </option>
              <option value="en">
                {{ $t('Inglese') }}
              </option>
              <option value="fr">
                {{ $t('Francese') }}
              </option>
              <option value="de">
                {{ $t('Tedesco') }}
              </option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="embedFor === null">
        <h3>{{ $t('Contatti') }}</h3>
        <div v-if="company.stato == 'IT'" class="row">
          <div class="form-group col-md-4">
            <label for="companyPEC">
              {{ $t('Email') }} <abbr :title="$t('Posta Elettronica Certificata')">{{ $t('PEC') }}</abbr>
            </label>
            <input
              id="companyPEC"
              v-model="company.email_pec"
              type="email"
              class="form-control"
              maxlength="100"
              autocomplete="email"
            >
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
            <label for="companyTelefono">{{ $t('Telefono') }} *</label>
            <input
              id="companyTelefono"
              v-model="company.telefono"
              type="tel"
              class="form-control"
              required
              maxlength="20"
              autocomplete="tel"
            >
          </div>
        </div>
      </template>

      <div v-if="isForm" class="form_buttons">
        <button id="submit_billing" type="submit" class="btn btn-primary pull-left">
          {{ $t('Invia') }}
        </button><em>* {{ $t('Campo obbligatorio') }}</em>
      </div>
    </fieldset>
  </component>
</template>

<style scoped>
.approval_info{
  margin-top:10px;
}
.form_buttons{
  margin-top: 20px;
}
.btn-primary {
  margin: 0px 10px 0 0;
}
</style>

<script lang="ts">
import { http } from '../http';
import countries from '../countries.json';

import BtnRadio from '../components/btn-radio.vue';
import Toggle from '../components/toggle.vue';
import { defineComponent, unref, type PropType } from 'vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { useCompany } from '../utils';
import { useCompanyStore } from '@/store/company';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { type Company, CompanyResource } from '@/resources';
import axios from 'axios';
import { $t } from '@/i18n';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

export default defineComponent({
  name: 'BillingForm',

  components: {
    BtnRadio,
    Toggle,
    FormHelpTooltip,
  },

  props: {
    embedFor: {
      type: String as PropType<'prospect' | 'order' | 'approval' | null>,
      default: null,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit'],

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        asyncCompany: useCompany(),
      }),
    };
  },

  data(this: void) {
    return {
      sending: false,
      countries,
      autoCities: [] as string[],
      autoProvince: false,
      company: { locked: false, currency_locked: false, tipologia: 'Company', fatturazione_mensile: false } as Partial<Company>,
    };
  },

  computed: {
    persona_fisica() {
      return this.company.tipologia == 'Private';
    },

    verificato() {
      return this.login.verificato;
    },

    loading() {
      return this.sending || this.asyncComputed.asyncCompany.state !== 'success';
    },

    isForm() {
      return this.embedFor !== 'prospect';
    },

    ragioneSocialeLabel() {
      return this.company.tipologia === 'Company' ? $t('Azienda') : $t('Nome e Cognome');
    },
  },

  watch: {
    asyncCompany: {
      handler() {
        if (this.embedFor !== 'prospect' && this.asyncCompany && this.company.id !== this.asyncCompany.id) {
          this.company = { ...unref(this.asyncCompany) };
        }
      },
      immediate: true,
    },
  },

  methods: {
    async capChange() {
      this.autoCities = [];

      if (!this.company.cap) {
        return;
      }

      try {
        const response = await http.get(`/api/zipcode/${this.company.stato}/${this.company.cap}`, {
          withCredentials: false,
        });

        for (const p of response.data.places) {
          if (!this.autoCities.includes(p['place name'])) {
            this.autoCities.push(p['place name']);
          }
        }
        this.autoCities.sort();
      } catch (e: any) {
        if (!axios.isAxiosError(e) || e.response?.status !== 404) {
          throw e;
        }
      }

      if (this.autoCities.length > 0) {
        const oldCity = this.company.citta;
        if (!oldCity || !this.autoCities.includes(oldCity)) {
          this.company.citta = this.autoCities[0];
        } else {
          this.company.citta = undefined;
          setTimeout(() => {
            this.company.citta = oldCity;
          }, 100);
        }
      }

      if (this.company.stato != 'IT') {
        this.autoProvince = false;
        return;
      }

      try {
        this.company.provincia = (await http.get(`/rest/province/${this.company.cap}`)).data;
        this.autoProvince = true;
      } catch (e) {
        this.autoProvince = false;
      }
    },

    async submit() {
      if (!this.company.id) {
        return;
      }

      const app = useAppStore();

      this.sending = true;
      try {
        this.company = await new CompanyResource().save(this.company.id, this.company);
        app.toast($t('Profilo aggiornato'), 'success');
        this.$emit('submit');
        if (this.company.id === this.login.id_azienda) {
          await this.verificaProfilo();
        }
      } finally {
        this.sending = false;
      }
    },

    verificaProfilo() {
      const company = useCompanyStore();
      if (company.id) {
        return company.refresh();
      }
      return this.login.refresh();
    },
  },
});
</script>
