<template>
  <li class="list-group-item nav-group" v-show="visible">
    <a @click="showSubmenu = !showSubmenu" :title="title">
      <chevron-down-icon class="pficon" v-if="showSubmenu" />
      <chevron-right-icon class="pficon" v-else />
      <span class="list-group-item-value">{{ title }}</span>
    </a>
    <ul v-show="showSubmenu">
      <slot  />
    </ul>
  </li>
</template>

<style lang="scss" scoped>
@import '~patternfly/patternfly/variables';

.list-group-item.nav-group {
  background-color: $navbar-pf-vertical-bg-color;

  ul {
    padding: 0;
  }
}
</style>

<script  lang="ts">
export const GrantedLinkKey: ChildrenTrackerInjectionKey<InstanceType<typeof GrantedLink>> = Symbol("GrantedLinkKey");
</script>

<script lang="ts" setup>
import { ref, computed, toValue } from 'vue';
import { provideChildrenTracker, type ChildrenTrackerInjectionKey } from '@common/use';
import ChevronRightIcon from '@vue-patternfly/icons/chevron-right-icon';
import ChevronDownIcon from '@vue-patternfly/icons/chevron-down-icon';
import type GrantedLink from './granted-link.vue';
import { invoke, until } from '@vueuse/core';

const menuItems = provideChildrenTracker(GrantedLinkKey);
const visible = computed(() => menuItems.value.some(i => toValue(i.$.exposed?.visible)));
const active = computed(() => menuItems.value.some(i => toValue(i.$.exposed?.active)));

invoke(async () => {
  await until(active).toBeTruthy();
  showSubmenu.value = true;
});

defineProps<{
  title: string
}>();

const showSubmenu = ref(false);
</script>
