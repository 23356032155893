<template>
  <span v-if="product.nome" v-bind="ouiaProps">
    <template v-if="variant === 'list'">{{ label }}:</template>
    <s v-if="prezzo != prezzo_scontato">
      {{ $currency(prezzo, wizard.customer?.currency) }}
    </s>
    {{ ' ' }}
    <component :is="variant === 'list' ? 'strong' : 'span'">
      {{ $currency(prezzo_scontato, wizard.customer?.currency) }}
      <component :is="variant === 'list' ? 'span' : 'div'">
        <template v-if="wizard.customer === undefined || wizard.customer.vat > 0"> + {{ $t('IVA') }} </template> / {{ $t(product.durata) }}
      </component>
    </component>
  </span>
</template>

<script lang="ts">
import { $t } from '@/i18n';
import { defineComponent, type PropType } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizard } from './composition';
import type { Product } from './wizard.vue';

export default defineComponent({
  name: 'WizardPriceTag',

  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    variant: {
      type: String as PropType<'table' | 'list'>,
      default: 'list',
    },
    label: {
      type: String,
      default: $t('Prezzo'),
    },

    productInstead: Object as PropType<Product>,

    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      wizard: useWizard(),
    };
  },

  computed: {
    prezzo() {
      if (!this.productInstead) {
        return this.product.prezzo;
      }

      return this.product.prezzo - this.productInstead.prezzo;
    },

    prezzo_scontato() {
      if (!this.productInstead) {
        return this.product.prezzo_scontato;
      }

      return this.product.prezzo_scontato - this.productInstead.prezzo_scontato;
    },
  },
});
</script>
