<template>
  <v-page :name="_t('Dati aziendali')">
    <div v-if="login.has_recurring_payments || login.verificato" class="panel panel-default panel-affiliate-link">
      <div class="panel-heading">{{ _t('Modalità di pagamento') }}</div>
      <div v-if="login.has_recurring_payments" class="panel-body">
        {{ _t('Pagamenti ricorrenti attivi. Tutte le prossime fatture verranno saldate automaticamente.') }}

        <pf-button :disabled="!login.grants.billing || sendingRecurringPayments" @click="disableRecurringPayments">
          {{ _t("Disattiva") }}
        </pf-button>
        <form-help-tooltip v-if="!login.grants.billing" :text="_t('Non hai i permessi per disattivare i pagamenti ricorrenti. Richiedili ad un utente amministratore.')" />
      </div>
      <div v-else-if="login.verificato" class="panel-body">
        <triangle-exclamation-icon /> {{ _t('Pagamenti ricorrenti non attivi. Per attivarli spunta la relativa opzione quando effettuerai il prossimo pagamento di un ordine o di una fattura.') }}
      </div>
    </div>

    <div class="panel panel-default panel-affiliate-link">
      <div class="panel-heading">{{ _t('Modalità e dati di fatturazione') }}</div>
      <div class="panel-body">
        <p>
          <billing-form />
        </p>
      </div>
    </div>

  </v-page>
</template>

<script lang="ts" setup>
import { default as VPage } from '../components/page.vue';
import BillingForm from '../components/billing-form.vue';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { CompanyResource } from '@/resources';
import { ref } from 'vue';
import { $t as _t } from '@/i18n';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';

defineOptions({
  name: 'CompanyPage',
});

const login = useLoginStore();
const sendingRecurringPayments = ref(false);

async function disableRecurringPayments() {
  const app = useAppStore();

  if (login.id_azienda === null || !confirm(_t('Sei sicuro di voler disattivare i pagamenti ricorrenti automatici?'))) {
    return;
  }

  sendingRecurringPayments.value = true;
  try {
    await new CompanyResource().save(login.id_azienda, {
      disableRecurringPayments: true,
    });
    app.toast(_t('Pagamenti automatici disattivati'), 'success');
    await login.refresh();
  } finally {
    sendingRecurringPayments.value = false;
  }
}
</script>
