<template>
  <div v-if="loading" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ _t('Caricamento skill...') }}
    </h3>
  </div>
  <form v-else role="form" class="form-skill" @submit.prevent="submit">
    <h3>{{ _t('Quali sono le tue skill?') }}</h3>
    <div class="switchlist">
      <div v-for="skill in [...skills]" :key="skill.id" class="switch">
        <toggle
          v-model="company.requirements"
          :on-value="skill.id"
          name="requirements[]"
          :off-text="_t('No')"
          :on-text="_t('Sì')"
        >
          {{ skill.nome }}
        </toggle>
      </div>
    </div>

    <template v-if="company.requirements.length">
      <h3>{{ _t('Skill Principale') }}</h3>
      <p>{{ _t('Quale sai fare meglio? Scegline una, che considereremo la tua skill principale.') }}</p>
      <div class="switchlist">
        <div v-for="skill in mainSkills" :key="skill.id" class="switch">
          <label>
            <input
              v-model="company.id_main_skill"
              type="radio"
              name="id_main_skill"
              :value="skill.id"
              required
            >
            {{ skill.nome }}
          </label>
        </div>
      </div>
    </template>

    <h3>{{ _t('Tecnologie') }}</h3>
    <p>{{ _t('Con quali tecnologie lavori principalmente?') }}</p>
    <div class="switchlist">
      <div v-for="tech in techSkills" :key="tech.id" class="switch">
        <toggle
          v-model="company.requirements"
          :on-value="tech.id"
          name="requirements[]"
          :off-text="_t('No')"
          :on-text="_t('Sì')"
        >
          {{ tech.nome }}
        </toggle>
      </div>
    </div>

    <h3>{{ _t('Settori merceologici') }}</h3>
    <p>{{ _t('In quali settori merceologici operi principalmente?') }}</p>
    <div class="switchlist">
      <div v-for="bs in businessSectors" :key="bs.id" class="switch">
        <toggle
          v-model="company.requirements"
          :on-value="bs.id"
          name="requirements[]"
          :off-text="_t('No')"
          :on-text="_t('Sì')"
        >
          {{ bs.nome }}
        </toggle>
      </div>
    </div>

    <div class="form-buttons">
      <button id="restore-skill" type="button" class="btn btn-default" :disabled="sending" @click="askReset">
        {{ _t('Ripristina') }}
      </button>
      <button id="submit-skill" type="submit" class="btn btn-primary" :disabled="sending || disableSubmit">
        {{ _t('Invia') }}
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>

.switchlist {
  column-width: 250px;

  .switch {
    margin-bottom: 5px;
    min-width: 250px;
  }
}

.form-buttons {
  margin-top: 40px;
}
.login-pf-page .btn-primary {
    margin-top: 0px;
}
#submit-skill {
  margin-left: 10px;
}
</style>

<script lang="ts" setup>
import { useCompany } from '../utils';
import { RequirementsResource, type Requirements } from '../resources';
import { setupAsyncComputed } from '@common/asyncComputed';
import { $t as _t } from '@/i18n';
import { computed, ref, unref, watch } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import { CompanyResource } from '@/resources';
import { useAppStore } from '@/store/app';
import { useLoginStore } from '@/store/login';
import Toggle from '../components/toggle.vue';

const props = defineProps<{
  embedded?: boolean;
  required?: boolean;
}>();

const emit = defineEmits<{
  (e: 'submit'): void
}>();

const origCompany = useCompany();
const asyncCompany = origCompany.state;
const company = ref(cloneDeep(unref(origCompany.result.value)));
const wasEmpty = ref(false);
const sending= ref(false);
const login = useLoginStore();

const reset = () => {
  company.value = cloneDeep(unref(origCompany.result.value));
  wasEmpty.value = company.value.requirements.length === 0;
};

const { requirements } = setupAsyncComputed({
  requirements: {
    async get(this: any): Promise<Requirements[]> {
      const totp = await new RequirementsResource().get();
      return totp;
    },
    default: [],
  },
});

const loading = computed(() => sending.value || asyncCompany.state !== 'success');
const disableSubmit = computed(() => props.required && (!company.value.requirements.length || !company.value.id_main_skill));

const skills = computed(() => requirements.value.filter(r => r.tipo === 'skill').sort((a, b) => a.nome < b.nome ? -1 : 1 ));
const mainSkills = computed(() => skills.value.filter(x => company.value.requirements.includes(x.id)));
const techSkills = computed(() => requirements.value.filter(r => r.tipo === 'tech skill').sort((a, b) => a.nome < b.nome ? -1 : 1 ));
const businessSectors = computed(() => requirements.value.filter(r => r.tipo === 'business sector').sort((a, b) => a.nome < b.nome ? -1 : 1 ));

watch(origCompany.result, reset);

watch(mainSkills, () => {
  if (company.value.id_main_skill && !company.value.requirements.includes(company.value.id_main_skill)) {
    company.value.id_main_skill = null;
  }
});

async function submit() {
  sending.value = true;
  try {
    if (!props.embedded && !wasEmpty.value && !company.value.requirements.length && !confirm(_t("Confermando l'invio del form eliminerai tutte skill selezionate. Vuoi procedere?"))) {
      return;
    }
    company.value = await new CompanyResource().save(company.value.id, {
      id_main_skill: company.value.id_main_skill,
      requirements: company.value.requirements,
    });
    useAppStore().toast(_t('Skill aggiornate'), 'success');
  } finally {
    sending.value = false;
  }
  login.refresh();
  emit('submit');
};

function askReset() {
  if (confirm(_t('Vuoi ripristinare i valori iniziali?'))) {
    reset();
  }
};
</script>
