<template>
  <form role="form">
    <div v-for="ccp of categories" class="form-group row">
      <label for="companyRagioneSociale" class="col-xs-12 col-md-6">{{ ccp.category }}</label>
      <input
        v-model="ccp.markup"
        :name="ccp.category"
        type="number"
        min="0"
        step="1"
        class="col-xs-3 col-md-1"
        required
      > %
    </div>
    <div class="form-group row">
      <button type="button" class="btn btn-primary" @click="save">
        {{ $t('Salva') }}
      </button>
    </div>
  </form>
</template>

<style scoped>
.form-group input, .form-group button {
  margin-left: 20px;
}
.form-group input {
  padding: 0;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import { type CategoryCustomPrice, CategoryCustomPricesResource } from '@/resources';
import { cloneDeep } from 'lodash-es';

const props = defineProps<{
  categoryCustomPrices: CategoryCustomPrice[];
}>();

const categories = ref(cloneDeep(props.categoryCustomPrices));

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

async function save() {
  await new CategoryCustomPricesResource().save(categories.value);
  emit('submit');
}
</script>
